@import '../node_modules/react-phone-input-2/lib/material.css';
@import '../node_modules/mapbox-gl/dist/mapbox-gl.css';

@font-face {
  font-family: montserrat;
  src: url('/fonts/Montserrat-Medium.ttf');
}

@font-face {
  font-family: montserrat;
  src: url('/fonts/Montserrat-Bold.ttf');
  font-weight: bold;
}

body {
  margin: 0;
  font-family: 'montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.light-purple {
  color: #ca58f4 !important;
}

.yellow {
  color: #f8b133 !important;
}

.vertical-bottom {
  vertical-align: bottom;
}

.vertical-middle {
  vertical-align: middle;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.inline-block {
  display: inline-block !important;
}

.white-page {
  background-color: #fff;
  padding: 30px;
}

.light-gray-page {
  background-color: #f5f5f5;
  padding: 30px;
}

.title {
  font-weight: bold !important;
  color: #4a4a4a;
  margin-bottom: 20px !important;
}

.text-white {
  color: #fff;
}

.bold {
  font-weight: bold !important;
}

.status-ok {
  color: #93ca00;
}

.status-failed {
  color: red;
}

.status-cancelled {
  color: #9f9f9f;
}

.status-progress {
  color: #f8b133;
}

.phone-input .react-tel-input .form-control {
  width: 100%;
}

.phone-input .react-tel-input .form-control + div:before {
  display: none !important;
}

.opacity-0 {
  opacity: 0;
}

.helper-text {
  margin: 3px 14px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.75rem;
}

.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.overflow-hidden {
  overflow: hidden;
}

#marker {
  cursor: pointer;
}

.marker-text {
  position: relative;
}

.marker-text span {
  color: white;
  position: absolute;
  left: 14px;
  top: 7px;
  font-size: 14px;
  font-weight: bold;
}

.swal-content-scroll {
  max-height: 250px;
  overflow-y: auto;
}

.swal-content-scroll p {
  margin: 11px 0;
  font-size: 0.8em;
}

.title-swal {
  margin: 0 0 15px;
  line-height: 0.8;
}

.title-swal small {
  font-size: 0.45em;
}

.container-dropzone .MuiDropzoneArea-root {
  min-height: 180px;
}

@media (max-width: 600px) {
  .text-center-xs {
    text-align: center;
  }
  .white-page,
  .light-gray-page {
    padding: 30px 15px;
  }
  .padding-0-xs {
    padding: 0 !important;
  }
  .hidden-xs {
    display: none;
  }
}

@media (max-width: 960px) {
  .text-center-sm {
    text-align: center;
  }
  .text-left-sm {
    text-align: left;
  }
  .title {
    font-size: 20px !important;
  }
  .hidden-sm {
    display: none;
  }
}

/* Landing animations */
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
/* End Landing animations */
